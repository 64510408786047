/**
 *
 * @description Section.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(5),
  },
}))

export default function Section(props) {
  const { children } = props
  const classes = useStyles()
  return <section className={classes.section}>{children}</section>
}
