/**
 *
 * @description SlideCarousel.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { makeStyles, Typography, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  carousel: {
    textAlign: 'center',
    '& carousel': {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
}))

const products = [
  'Stack Magazine',
  'Channel Magazine',
  'Leadframe Magazine',
  'Container and Carrier',
  'Wafer Cassette',
  'End Cover',
]

const CAROUSEL_WIDTH = 700
export default function SlideCarousel() {
  const classes = useStyles()
  const [active, setActive] = React.useState(0)
  const [carouselWidth, setCarouselWidth] = React.useState(CAROUSEL_WIDTH)

  React.useEffect(() => {
    function handleResize() {
      setCarouselWidth((window.innerWidth > 700 && CAROUSEL_WIDTH) || null)
    }

    handleResize()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return (
    <>
      <Box marginBottom={2}>
        <Typography style={{ fontSize: '2rem', lineHeight: '2.5rem', fontWeight: 300 }} align='center'>
          {products[active]}
        </Typography>
      </Box>
      {typeof window !== 'undefined' && (
        <Carousel
          showStatus={false}
          dynamicHeight
          width={carouselWidth}
          autoPlay
          infiniteLoop
          interval={5000}
          emulateTouch={true}
          className={classes.carousel}
          onChange={(index, item) => {
            setActive(index)
          }}
        >
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/stack-magazine.png')} alt='stack magazine' />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/channel-magazine.png')} alt='channel magazine' />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/leadframe-magazine.png')} alt='leadframe magazine' />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/container-carrier.png')} alt='container and carrier' />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/wafer-cassette.png')} alt='wafer cassette' />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <img src={require('../images/slide/end-cover.png')} alt='end cover' />
          </div>
        </Carousel>
      )}
    </>
  )
}
