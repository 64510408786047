import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SlideCarousel from '../components/SlideCarousel'
import Section from '../components/Section'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title='Home' />

      <Section>
        <SlideCarousel />
      </Section>
    </Layout>
  )
}

export default IndexPage
